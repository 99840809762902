import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    Image,
    Link,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';

function About() {
    // Settings for the slider
    const settings = {
        arrows: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = useState(null);

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '40px' });

    // This list contains all the data for carousels
    // This can be static or loaded from a server
    const cards = [
        {
            author: 'Alison & Michael, Maryland',
            text:
                "We decided to have our honeymoon in Belize and I think it was the perfect choice! Tranquility Bay was the perfect choice of accommodation & quiet, pretty, secluded. Every morning we enjoyed a wonderful breakfast, lounged on the private beach, stepped into the water and snorkeled around the reefs. It was paradise.",
        },
        {
            author: 'Jane, Illinois',
            text:
                "You can walk along the beach and the only sounds you hear are the waves of the ocean, birds, and the occasional thump of a coconut falling from the tree. I think the quiet intimacy of the island makes it one of the most romantic caribbean vacations that I’ve ever been on.",
        },
        {
            author: 'William, North Carolina',
            text:
                "The staff were fabulous!! They went out of their way to accommodate us and make sure we were happy with every detail of our stay. The staff are what make a hotel great, which is why I think that this is one of the best resorts in Belize.",
        },
        {
            author: 'Orla & David, Virginia',
            text:
                "The team at Tranquility Bay Resort worked so hard to make our dream Belize destination wedding absolutely perfect! From the tropical floral arrangements to the altar made out of palm trees, every detail was stunning and more than I could have ever imagined! Our guests all had the time of their lives and we always cherish the memories!",
        },
        {
            author: 'Anna, Montana',
            text:
                "The worst thing about going to Tranquility Bay Resort is having to leave. This Belize resort is a gem.",
        },
        {
            author: 'Ellen & Tom, Arizona',
            text:
                "Travel to Belize is a terrific experience. It’s still authentic, still real and the people are very friendly",
        },
    ];

    return (
        <Container maxW={'7xl'} overflowX={'hidden'}>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 10, md: 20 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text as={'span'} color={'teal.400'}>
                            Escape to
                        </Text>
                        <br />
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'teal.300',
                                zIndex: -1,
                            }}>
                            serenity & seclusion
                        </Text>
                    </Heading>
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        Leave the world behind while you lounge on Tranquility Bay Resort’s private beach or swim in the calm, clear waters of the Caribbean Sea. Practice the art of “doing nothing” or spend your days exploring the natural beauty that surrounds the resort.
                    </Text>

                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>
                    <Blob
                        w={'125%'}
                        h={'130%'}
                        position={'absolute'}
                        top={'-10%'}
                        left={'-15%'}
                        zIndex={-1}
                        color={useColorModeValue('teal.50', 'teal.400')}
                    />
                    <Box
                        position={'relative'}
                        height={'300px'}
                        rounded={'2xl'}
                        boxShadow={'lg'}
                        width={'full'}
                        overflow={'hidden'}>
                        <Image
                            alt={'Beachfront View'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={
                                '/beachfront.jpg'
                            }
                        />
                    </Box>
                </Flex>
            </Stack>
            <Stack pl={0} pr={0} spacing={4} as={Container} maxW={'7xl'} textAlign={'center'} mb="12">
                <Box
                    position={'relative'}
                    height={'300px'}
                    rounded={'2xl'}
                    width={'full'}
                    overflow={'hidden'}
                    mb="12"
                    boxShadow={'lg'}>
                    <Image
                        alt={'Cabanas'}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                        src={
                            '/Tranquility-Bay-Resort.jpg'
                        }
                    />
                </Box>
                <Heading fontSize={'3xl'} align={'center'} mb="6">Family Fun</Heading>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Relax and enjoy the beach right outside your door where you and your children can swim the clear caribbean waters, play in the sand or just soak up the plentiful sunshine on our serene and tranquil beach.Swings to play on, kayaks to paddle and the reef to snorkel are right at your fingertips.
                    You can book a beachside or in room massage to relax and recharge. Just speak with one of our staff to make arrangements.
                </Text>
                <Heading fontSize={'3xl'} align={'center'} mb="6">Fisherman’s Paradise</Heading>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    A fly fisherman's dream with day to a potential Grand Slam within walking distance. Guided tours are also always available. The nearby Basil Jones Cut offers quick access to deep sea  fishing for those who are so inclined. And of course our location is surrounded by beautiful reef fishing areas where after a successful day your bounty can be enjoyed in a cookout on the beach or prepared as you wish in our kitchen.
                </Text>
                <Heading fontSize={'3xl'} align={'center'}>Discover Tranquility Bay Resort</Heading>
                <HStack spacing={8} mt="8">
                    <Text color={'gray.600'} align={'left'} fontSize={{ base: "md", md: "lg" }}>
                        Tranquility Bay Resort is located in Belize 13.5 Miles North of Ambergris Caye in the Basil Jones Area on 12 acres at the northeastern edge of Ambergris Caye. Our island resort is a hidden gem with unique beachfront access to the Belize Barrier Reef – a spectacular underwater world featuring colourful sea life. This virtually undiscovered portion of Belize is perfect for travellers looking to unplug from it all and unwind.
                    </Text>
                    <Text color={'gray.600'} align={'left'} fontSize={{ base: "md", md: "lg" }}>
                        While our location is astounding, our staff makes Tranquility Bay Resort truly unique. Comprised of American expats and English-speaking Belizean nationals, our staff has cultivated the art of unobtrusive hospitality. Enjoy world-class meals at the Aquarium Restaurant. Discover the power of a perfect Rum Punch at our Tacklebox Sea Bar. Dive, Fish or arrange any excursion so you can explore the beauty of Belize.
                    </Text>
                </HStack>
            </Stack>
            <Flex flexDir={{ base: 'column', md: 'row' }} flex={{ base: 1 }} mb={'12'}>
                <Flex flex={1} justify={{ base: 'center', md: 'center' }} align={'center'} mb={{ base: '6', md: '0' }}>
                    <Box
                        position={'relative'}
                        rounded={'2xl'}
                        maxW={'30em'}
                        overflow={'hidden'}
                        mb="12"
                        boxShadow={'lg'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open("https://www.flightconnections.com/flights-to-belize-city-bze", '_blank')}>
                        <Image
                            alt={'Map of Flights to Belize'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={
                                '/map-of-flights-to-belize.png'
                            }
                        />
                    </Box>
                </Flex>
                <Flex flex={1} justify={{ base: 'center', md: 'flex-end' }}>
                    <Accordion allowMultiple width="97%" color={'white'}>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')} roundedTopLeft={'2xl'} roundedTopRight={'2xl'} _hover={{ bg: 'teal.500' }}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>When is the best time to visit Belize?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    There is no bad time to visit Belize. The weather is mild all year long with temperatures ranging between 60 to 97 degrees Fahrenheit. The trade winds provide a light breeze from the ocean year round.
                                    <br /><br />
                                    The winter season (December through April) is the most popular tourism season. Daytime winter temperatures average around 85 degrees and the water is still mild. Occasionally a wetsuit (3 mil) is used for snorkeling or diving, but not absolutely necessary.
                                    <br /><br />
                                    Wedding and honeymoon guests prefer May, June, and September – the climate is beautiful, prices are lower, and flowers are at their best.
                                    <br /><br />
                                    Families often choose to come in July and August while school is out. There are occasional rain showers this time of year but they are brief and often happen at night – leaving a sunny, fresh start to the day.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _hover={{ bg: 'teal.500' }}>
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>
                                    How do you get to Tranquility Bay Resort?
                                </Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    Fly from a major airport to the Philip S. W. Goldson International Airport (BZE) in Belize City, Belize.
                                    After going through customs, you’ll take a short commuter flight to San Pedro on Ambergris Caye.
                                    We can assist you in arranging transportation to the resort at an extra fee.                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _hover={{ bg: 'teal.500' }}>
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>Do you schedule trips into San Pedro?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    We do not have scheduled trips to San Pedro, but if you would like to go to town we can assist you with this at an extra cost. Please note that we ask for 24 hours notice for us to arrange transportation.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _hover={{ bg: 'teal.500' }}>
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>What kind of electricity does Belize use?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    Electrical voltage (110-120) and outlets are the same as North America.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _hover={{ bg: 'teal.500' }}>
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>Which currency is accepted?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    We recommend using US dollars – they are widely accepted throughout Belize although US coins are not. You’ll often receive change in Belize dollars. The Belize dollar is fixed to the American Dollar at a rate of 2 BZD to 1 USD.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')} _hover={{ bg: 'teal.500' }}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>Which language is spoken in Belize?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    English is the official language of Belize.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')} _hover={{ bg: 'teal.500' }}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>How about tipping?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    Tipping is customary in Belize and similar to North America – 10-20% is standard. Although any amount is welcome and as always, at your discretion.
                                    <br /><br />
                                    Tranquility Bay Resort’s nightly rates include a 10% service charge. Bar service, meals, and tours do not include a gratuity.
                                    <br /><br />
                                    Often guests choose to leave a general gratuity at the end of their stay and let our management divide it among the staff. If there is someone who provided exceptional service, we’d love to know!
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')} _hover={{ bg: 'teal.500' }}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>What should I pack?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    <b>Clothing</b>
                                    <br />
                                    &#8226; Lightweight breathable shirts and pants-both long sleeve and long pants in addition to the short sleeve/sleeveless tops and short pants.
                                    <br />
                                    &#8226; One pair of closed toe walking shoes
                                    <br />
                                    &#8226; Two pair of flip flops/sandals
                                    <br />
                                    &#8226; Windbreaker or light jacket or sweater
                                    <br />
                                    &#8226; Hat
                                    <br />
                                    &#8226; At least two swimsuits
                                    <br />
                                    &#8226; Raincoat or poncho
                                    <br />
                                    <br />
                                    <b>Gear</b>
                                    <br />
                                    &#8226; Waterproof day pack
                                    <br />
                                    &#8226; Refillable water bottle
                                    <br />
                                    &#8226; Sunscreen and lip balm with SPF
                                    <br />
                                    &#8226; Insect repellent
                                    <br />
                                    &#8226; Sunglasses
                                    <br />
                                    &#8226; Binoculars
                                    <br />
                                    &#8226; Flashlight/headlamp with extra batteries
                                    <br />
                                    &#8226; Camera
                                    <br />
                                    &#8226; Birders, don’t forget your bird book!
                                    <br />
                                    <br />
                                    <b>Traveling with small children</b>
                                    <br />
                                    &#8226; Life jacket for a small child—all boats have life jackets but may not always have small sizes
                                    <br />
                                    &#8226; Beach toys
                                    <br />
                                    &#8226; Favorite foods, formula, diapers, swim diapers—these items are available in town but not anywhere close to our resort
                                    <br />
                                    <br />
                                    <b>Personal items</b>
                                    <br />
                                    &#8226; Any prescription and non-prescription medication you may need
                                    <br />
                                    &#8226; Bandaids and antibiotic ointment
                                    <br />
                                    &#8226; Contact lens supplies if needed
                                    <br />
                                    &#8226; Toiletries: toothbrush, toothpaste, body soap, after-sun lotion, shampoo and razors
                                    <br />
                                    <br />
                                    <b>Miscellaneous</b>
                                    <br />
                                    &#8226; Ziplock bags
                                    <br />
                                    &#8226; Charging cord for your phone, laptop etc.
                                    <br />
                                    &#8226; Cash
                                    <br />
                                    &#8226; Large beach/boat bag to carry on day trips
                                    <br />
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem bg={useColorModeValue('teal.400', 'teal.400')} roundedBottomLeft={'2xl'} roundedBottomRight={'2xl'} _hover={{ bg: 'teal.500' }}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text fontWeight={'bold'} fontSize={{ base: "md", md: "lg" }} align={'left'}>Do I need a passport or visa to travel to Belize?</Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text>
                                    Visitors to Belize must possess a passport valid for at least 30 days after the date of arrival (not in transit to a third country) and a return ticket with sufficient funds to cover their stay. Visa requirements for entry to Belize vary by country, but citizens of the United States, Canada, United Kingdom, and European Union do not need a visa. {' '}
                                    <Link textDecoration={"underline"} href={"https://www.travelbelize.org/getting-here/visas-and-immigration/"}>Additional Passport & Visa Information</Link>
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>
            <Heading fontSize={'3xl'} align={'center'} mb="6">Testimonials</Heading>
            <Box
                position={'relative'}
                height={'sm'}
                width={'full'}
                overflow={'hidden'}
                mb="12">
                {/* CSS files for react-slick */}
                <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                {/* Left Icon */}
                <IconButton
                    aria-label="left-arrow"
                    variant="ghost"
                    position="absolute"
                    left={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    color={"teal.500"}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}>
                    <BiLeftArrowAlt size="40px" />
                </IconButton>
                {/* Right Icon */}
                <IconButton
                    aria-label="right-arrow"
                    variant="ghost"
                    position="absolute"
                    right={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    color={"teal.500"}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}>
                    <BiRightArrowAlt size="40px" />
                </IconButton>
                {/* Slider */}
                <Slider {...settings} ref={(slider) => setSlider(slider)}>
                    {cards.map((card, index) => (
                        <Box
                            key={index}
                            height={'sm'}
                            position="relative"
                            borderStyle={"solid"}
                            borderColor={"teal.500"}
                            borderWidth={'5px'}
                            rounded={'2xl'}
                        >
                            {/* This is the block you need to change, to customize the caption */}
                            <Container size="container.lg" height='sm' position="relative">
                                <Stack
                                    spacing={6}
                                    w={'full'}
                                    maxW={{ base: '90%', md: 'lg' }}
                                    position="absolute"
                                    top="50%"
                                    transform="translate(0, -50%)">
                                    <Text fontSize={{ base: 'md', lg: 'lg' }} textAlign={'left'} color={'gray.600'}>
                                        {card.text}
                                    </Text>
                                    <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight={'bold'} color="teal.500" textAlign={'right'}>
                                        {card.author}
                                    </Text>
                                </Stack>
                            </Container>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Container>
    );
}

export const Blob = (props) => {
    return (
        <Icon
            width={'100%'}
            viewBox="0 0 578 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
                fill="currentColor"
            />
        </Icon>
    );
};

export default About;