import {
    ChevronDownIcon,
    ChevronRightIcon,
    CloseIcon,
    HamburgerIcon,
    PhoneIcon,
} from '@chakra-ui/icons';
import {
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    IconButton,
    Image,
    Link,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    useToken
} from '@chakra-ui/react';
import { IoLocationSharp } from "react-icons/io5";

export default function Navigation() {
    const { isOpen, onToggle } = useDisclosure();
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('teal.500', 'white');
    const isLandingPage = window.location.pathname === "/" || window.location.pathname === "";
    const [iconColor] = useToken(
        'colors',
        ['teal.500'],
    );

    return (
        <Box
            position={'sticky'}
            top={'0'}
            zIndex={1}
            width={'100%'}>

            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 3 }}
                px={{ base: 3 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
                flex={{ base: 2 }}>

                <Flex
                    flex={{ base: 0, md: 0, '2xl': 1 }}
                    display={{ base: 'none', '2xl': 'flex' }}
                >
                    <Link
                        p={2}
                        href={'tel:+501.670.5880'}
                        fontSize={'md'}
                        fontWeight={500}
                        color={linkColor}
                        _hover={{
                            textDecoration: 'none',
                            color: linkHoverColor,
                        }}>
                        <PhoneIcon mr={3} mt={-1} />
                        +501 670-5880
                    </Link>
                </Flex>
                {isLandingPage ?
                    <Flex
                        flex={{ base: 1 }}
                        display={{ base: 'flex', md: 'flex' }}
                        justify={{ base: 'flex-start', md: 'flex-start', '2xl': 'center' }}>
                        <Box mr={1} mt={0.25}>
                            <IoLocationSharp size={20} color={iconColor} />
                        </Box>
                        <Text color='teal.600' mr={3}>
                            Ambergris Caye, Belize
                        </Text>
                    </Flex> :
                    <Flex
                        flex={{ base: 1 }}
                        display={{ base: 'flex', md: 'flex' }}
                        justify={{ base: 'center', md: 'flex-start', '2xl': 'center' }}>
                        <Link
                            href='/'>
                            <Image src="/logo.png" alt="Tranquility Bay Logo"
                                maxHeight={{ base: '10' }}
                                maxWidth={{ base: 'none' }}
                            />
                        </Link>
                    </Flex>
                }

                <Spacer
                    flex={{ base: 0, md: 0 }}>
                </Spacer>

                <Flex
                    flex={{ base: 0, md: 1 }}
                    display={{ base: 'none', md: 'flex' }}
                    justify={{ base: 'flex-end' }}
                >
                    <DesktopNav />
                </Flex>

                <Flex
                    flex={{ base: 0, md: 0 }}
                    display={{ base: 'flex', md: 'none' }}
                    justify={{ base: 'flex-start' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>

            <MobileCallToAction />
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('teal.500', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    const openBookingEngine = () => {
        const url = `https://direct-book.com/properties/tranquilitybayresort&spadirect?locale=en&currency=USD`;
        window.open(url, '_blank');
    }

    return (
        <Stack direction={'row'} spacing={4} align='center'>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                p={{base: 0, lg: 2}}
                                href={navItem.href ?? '#'}
                                fontSize={'md'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
            <Button
                display={{ base: 'none', '2xl': 'flex' }}
                onClick={openBookingEngine}
                alignSelf={{ base: 'center', lg: 'flex-end' }}
                bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color={'white'}
                colorScheme="teal"
                _hover={{
                    bg: 'teal.400',
                }}>
                Book Now
            </Button>
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('teal.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'teal.500' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'teal.500'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'left'}
                pl={5}
                align={'center'}
                color={useColorModeValue('gray.600', 'gray.200')}
                _hover={{
                    textDecoration: 'none',
                    color: 'teal.500'
                }}>
                <Text fontWeight={'600'}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: 'About',
        href: '/about',
    },
    {
        label: 'Cabanas',
        href: '/cabanas',
    },
    {
        label: 'Activities',
        href: '/activities',
    },
    {
        label: 'Dining',
        href: '/dining',
    },
    {
        label: 'Romance',
        href: '/romance',
    },
    {
        label: 'Contact',
        href: '/contact',
    },
];

const MobileCallToAction = () => {
    return (
        <Flex
            bg={useColorModeValue('teal.500', 'teal.500')}
            color={useColorModeValue('white', 'white')}
            py={{ base: 0 }}
            px={{ base: 3 }}
            align={'center'}
            flex={{ base: 2 }}
            display={{ base: 'flex', '2xl': 'none' }}>

            <Flex
                flex={{ base: 1 }}
                justify={{ base: 'center' }}
            >
                <Link
                    p={2}
                    href={'tel:+501.670.5880'}
                    fontSize={'lg'}
                    fontWeight={700}
                    _hover={{
                        textDecoration: 'none',
                    }}>
                    <PhoneIcon mr={3} mt={-1} />
                    Call
                </Link>
            </Flex>

            <Flex
                flex={{ base: 1 }}
                justify={{ base: 'center' }}>
                <Link
                    p={2}
                    href={'https://direct-book.com/properties/tranquilitybayresort&spadirect'}
                    target={'_blank'}
                    fontSize={'lg'}
                    fontWeight={700}
                    color={'yellow.200'}
                    _hover={{
                        textDecoration: 'none',
                    }}>
                    Book Now
                </Link>
            </Flex>
        </Flex>
    );
};