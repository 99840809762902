import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react';



function Excursions() {
    return (
        <div>
            <Container maxW={'7xl'} overflowX={'hidden'} pt={8}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Excursions'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    '/excursions.jpg'
                                }
                                transform={'translate(0%, 0%)'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                Local
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                excursions
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            Sun drenched and fringed with coconut palms, Ambergris Caye is the largest of Belize's over 200 Cayes. Only a stones throw from Mexico's Yucatan Peninsula, the narrow Bacalar Chica channel dug by the ancient Maya separates it. Known as "La Isla Bonita", the island is filled with Caribbean charm. San Pedro, the main town, is a delightful blend of the Caribbean with a touch of Mexico and a dash of old Key West. Sandy streets are lined with small hotels, boutiques, dive stores and full menu of places to eat and drink.
                        </Text>
                    </Stack>
                </Stack>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    A very popular trip is diving or snorkeling at Hol Chan National Marine Preserve and Shark-Ray Alley, followed by lunch on the picturesque Island Caye Caulker. There are also boat trips to the famous Blue Hole, (rated one of the world’s top 10 dive sites), Lighthouse Reef, Half Moon Caye or the Turneffe Islands. A visit to the Mayan sites of Altun Ha or Lamanai, by boat is a great adventure.
                    <br></br><br></br>
                    There are also many wonderful things to do on the mainland of Belize. Tranquility Bay Resort can arrange and coordinate these activities including visits to Mayan ruins including Xunantunich, Caracol, Altun Ha and Lamanai (over 900 sites total), river rafting, zip lining, cave exploring and bird watching. Sites well worth seeing include the Mountain Pine Ridge country, the Cockscomb Jaguar Preserve, the Baboon Sanctuary and the Belize Zoo. There is always something spectacular available to do at or near Tranquility Bay. Let the adventure begin.
                </Text>
            </Container>
            <Grid templateColumns='repeat(3, 1fr)'>
                <GridItem w='100%' >
                    <Image src="/excursions1.jpg" roundedTopLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/excursions2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/excursions3.jpg" roundedTopRight={"2xl"} boxShadow={'lg'} />
                </GridItem>
            </Grid>
        </div>
    );
}

export default Excursions;