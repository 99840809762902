import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react';



function Snorkeling() {
    return (
        <div>
            <Container maxW={'7xl'} overflowX={'hidden'} pt={8}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Snorkeling'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    '/Snorkel-Feature.jpg'
                                }
                                transform={'translate(0%, 0%)'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                Snorkeling
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                from the beach
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            At Tranquility Bay snorkeling in the warm, calm, clear water inside the reef is spectacular. On any one outing you will see dozens and dozens of different varieties of fish, all in their Technicolor glory. The best part about it, you only have to walk out of your beachfront cabana door to snorkel the Belize Barrier Reef. There is a small amount of snorkeling gear available for rental so it is preferred if you have your gear to bring them along to make sure you have the right size with you as gear rental goes pretty quickly and we find that the perfect size is normally the hardest with limited stock.
                        </Text>
                    </Stack>
                </Stack>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Tranquility Bay Resort lies just inside the remarkable Belize Barrier Reef, the longest unbroken living reef on earth. It’s also the only resort on Ambergris Caye that is actually located inside the Bacalar Chico Marine Reserve, which means you can literally take a few steps out of your cabana, start snorkeling and experience an amazing variety of marine life.
                </Text>
            </Container>
            <Grid templateColumns='repeat(3, 1fr)'>
                <GridItem w='100%' >
                    <Image src="/snorkel1.jpg" roundedTopLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/snorkel2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/snorkel3.jpg" roundedTopRight={"2xl"} boxShadow={'lg'} />
                </GridItem>
            </Grid>
        </div>
    );
}

export default Snorkeling;