import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react';



function Fishing() {
    return (
        <div>
            <Container maxW={'7xl'} overflowX={'hidden'} pt={8}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Fishing'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    '/Fishing.png'
                                }
                                transform={'translate(0%, 0%)'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                    <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                Fishing
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                in paradise
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            At Tranquility Bay Resort, world‐class fishing is just steps away from your cabana’s front door. The waters of Ambergris Caye are widely considered to host some of the best fishing in Belize. The turquoise sea is clear and warm throughout the year, making it easy to see the swarms of fish. Whatever type you enjoy we can book the excursion for you – reef fishing, fly fishing, or deep sea fishing – our English speaking guide will provide an exceptional experience tailored to your needs, making your Belize fishing experience of the best vacations you’ve ever had!
                        </Text>
                    </Stack>
                </Stack>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Let Tranquility Bay Resort show you the best fishing Belize has to offer especially for our fishermen who get the advantage to sport fish just off our beach on the shoreline so don’t forget your fishing gear, Basil Jones area is known for bonefish, permit and tarpons.
                </Text>
            </Container>
            <Grid templateColumns='repeat(3, 1fr)'>
                <GridItem w='100%' >
                    <Image src="/fishing1.jpg" roundedTopLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/fishing2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/fishing3.jpg" roundedTopRight={"2xl"} boxShadow={'lg'} />
                </GridItem>
            </Grid>
        </div>
    );
}

export default Fishing;