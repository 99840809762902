import {
    Box,
    Button,
    Flex,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputRightAddon,
    useColorModeValue,
    useNumberInput
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './BookingBar.css';


function BookingBar() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const datePickerRef = useRef(null);
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
        step: 1,
        defaultValue: 2,
        min: 1,
        max: 99
    });

    const incGuests = getIncrementButtonProps();
    const decGuests = getDecrementButtonProps();
    const inputGuests = getInputProps();

    const openBookingEngine = () => {
        const checkInDate = startDate != null ? startDate.toISOString().slice(0, 10) : '';
        const checkOutDate = endDate != null ? endDate.toISOString().slice(0, 10) : '';
        const guests = inputGuests != null ? inputGuests.value : 2;

        const url = `https://direct-book.com/properties/tranquilitybayresort&spadirect?locale=en&items[0][adults]=${guests}&items[0][children]=0&items[0][infants]=0&currency=USD&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}`;
        
        window.open(url, '_blank');
    };

    useEffect(() => {
        if (datePickerRef.current !== null) {
            datePickerRef.current.input.readOnly = true;
        }
    }, [datePickerRef]);

    return (
        <div>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                px={4}
                py={4}>
                <Flex
                    direction={{ base: 'column', lg: 'row' }}
                    align={'start'}
                    justify={'space-between'}
                    gap='6'>
                    <FormControl id="dates" w={{ base: '18rem', lg: '14.5rem' }}>
                        <DatePicker
                            placeholderText="Arrival - Departure"
                            ref={datePickerRef}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            minDate={new Date()}
                            withPortal
                        />
                    </FormControl>
                    <FormControl id="guests" w={{ base: '18rem' }}>
                        <HStack>
                            <Button {...decGuests} size='lg' variant='outline' fontSize={'1.4rem'}>−</Button>
                            <InputGroup size='lg' >
                                <Input {...inputGuests} textAlign='center' />
                                <InputRightAddon children='guests' />
                            </InputGroup>
                            <Button {...incGuests} size='lg' variant='outline' fontSize={'1.4rem'}>+</Button>
                        </HStack>
                    </FormControl>
                    <Button
                        onClick={openBookingEngine}
                        size={'lg'}
                        alignSelf={{ base: 'center', lg: 'flex-end' }}
                        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                        color={'white'}
                        colorScheme="teal"
                        _hover={{
                            bg: 'teal.400',
                        }}>
                        Book Now
                    </Button>
                </Flex>
            </Box>
        </div>

    );
}

export default BookingBar;