import {
    Badge,
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Stack,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';

import { useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { BsFillPersonFill } from 'react-icons/bs';
import Slider from 'react-slick';

function Cabanas() {
    // Settings for the slider
    const settings = {
        arrows: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [cards, setCards] = useState([]);
    const [cardsTitle, setCardsTitle] = useState('');

    const openPrime = () => {
        setCards(['/prime1.jpg', '/prime2.jpg', 'prime3.jpg']);
        setCardsTitle('Prime Minister Suite');
        onOpen();
    };

    const openTwo = () => {
        setCards(['/twobed1.jpg', '/twobed2.jpg', 'twobed3.jpg', 'twobed4.jpg', 'twobed5.jpg']);
        setCardsTitle('Two Bedroom Cabana');
        onOpen();
    };

    const openOne = () => {
        setCards(['/onebed1.jpg', '/onebed2.jpg', 'onebed3.jpg', 'onebed4.jpg', 'onebed5.jpg']);
        setCardsTitle('One Bedroom Cabana');
        onOpen();
    };

    const openBudget = () => {
        setCards(['/budget1.jpg', '/budget2.avif']);
        setCardsTitle('Budget Traveler\'s Cabana');
        onOpen();
    };

    return (
        <Container maxW={'7xl'} overflowX={'hidden'}>

            <Modal isOpen={isOpen} onClose={onClose} size={'xxl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{cardsTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box position={'relative'} overflow={'hidden'}>
                            <link
                                rel="stylesheet"
                                type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                            />
                            <link
                                rel="stylesheet"
                                type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                            />
                            {/* Slider */}
                            <Stack>
                                <Slider {...settings} ref={(slider) => setSlider(slider)}>
                                    {cards.map((card, index) => (
                                        <Image key={index} src={card} w={'auto'} maxH={'60vh'} fit={'contain'}></Image>
                                    ))}
                                </Slider>
                                <Flex>
                                    <Spacer />
                                    {/* Left Icon */}
                                    <IconButton
                                        aria-label="left-arrow"
                                        zIndex={2}
                                        onClick={() => slider?.slickPrev()}>
                                        <BiLeftArrowAlt size="40px" />
                                    </IconButton>
                                    <Spacer />

                                    {/* Right Icon */}
                                    <IconButton
                                        aria-label="right-arrow"
                                        zIndex={2}
                                        onClick={() => slider?.slickNext()}>
                                        <BiRightArrowAlt size="40px" />
                                    </IconButton>
                                    <Spacer />
                                </Flex>
                            </Stack>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 10, md: 20 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '5xl', lg: '6xl' }}>
                        <Text as={'span'} color={'teal.400'}>
                            Beach cabanas
                        </Text>
                        <br />
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'teal.300',
                                zIndex: -1,
                            }}>
                            Belizean style
                        </Text>

                    </Heading>
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        Belize is known for its beaches, and Tranquility Bay Resort on Ambergris Caye delivers the quintessential seaside cabana experience. Enjoy some of the best beachfront cabanas Belize has to offer, that sit just steps away from warm, crystal blue waters, ready to welcome you to our version of secluded serenity. Away from the city’s noise and bustle yet convenient enough to access Belize’s many exciting activities, Tranquility Bay Resort will quickly feel like home. Staying in beach cabanas Belize style are the best way to enjoy your natural surroundings and truly relax.
                    </Text>

                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>
                    <Blob
                        w={'125%'}
                        h={'130%'}
                        position={'absolute'}
                        top={'-10%'}
                        left={'-15%'}
                        zIndex={-1}
                        color={useColorModeValue('teal.50', 'teal.400')}
                    />
                    <Box
                        position={'relative'}
                        height={'350px'}
                        rounded={'2xl'}
                        boxShadow={'lg'}
                        width={'full'}
                        overflow={'hidden'}>
                        <Image
                            alt={'Cabanas'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'120%'}
                            src={
                                '/Tranquility-Bay-Resort2.jpg'
                            }
                            transform={'translate(0%, -10%)'}
                        />
                    </Box>
                </Flex>
            </Stack>
            <Heading fontSize={'3xl'} align={'center'} mb="6">Take a 360° Tour</Heading>
            <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(300px, 1fr))' mb="12">
                <Flex w="full" alignItems="center" justifyContent="center">
                    <Box
                        bg={useColorModeValue('white', 'gray.800')}
                        maxW="lg"
                        h="lg"
                        borderWidth="1px"
                        rounded="lg"
                        shadow="lg"
                        position="relative">
                        <Image
                            h="300px"
                            fit="cover"
                            src="/Prime-minister.jpg"
                            roundedTop="lg"
                        />
                        <Box p="6">
                            <Flex justifyContent="space-between" alignContent="center">
                                <Box
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Prime Minister Suite
                                </Box>
                            </Flex>
                            <Box d="flex" alignItems="baseline" mb="10">
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal" mr="2">
                                    <HStack spacing={"0"}>
                                        <Text mr="1">Sleeps</Text>
                                        <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill />
                                    </HStack>
                                </Badge>
                                <br></br>
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal">
                                    Seafront View
                                </Badge>
                            </Box>
                            <Button mr="3" onClick={() => window.open("https://my.matterport.com/show/?m=xTPgU7XcQ6h", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                color={'white'}
                                colorScheme="teal"
                                _hover={{
                                    bg: 'teal.400',
                                }}>View in 360°</Button>
                            <Button onClick={openPrime}>Photos</Button>
                        </Box>
                    </Box>
                </Flex>
                <Flex w="full" alignItems="center" justifyContent="center">
                    <Box
                        bg={useColorModeValue('white', 'gray.800')}
                        maxW="lg"
                        h="lg"
                        borderWidth="1px"
                        rounded="lg"
                        shadow="lg"
                        position="relative">
                        <Image
                            h="300px"
                            fit="cover"
                            src="/2-Bedroom.jpg"
                            roundedTop="lg"
                        />
                        <Box p="6">
                            <Flex justifyContent="space-between" alignContent="center">
                                <Box
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Two Bedroom Cabana
                                </Box>
                            </Flex>
                            <Box d="flex" alignItems="baseline" mb="10">
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal" mr="2">
                                    <HStack spacing={"0"}>
                                        <Text mr="1">Sleeps</Text>
                                        <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill />
                                    </HStack>
                                </Badge>
                                <br></br>
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal">
                                    Seafront View
                                </Badge>
                            </Box>
                            <Button mr="3" onClick={() => window.open("https://my.matterport.com/show/?m=Eo1KHB9Pf4U", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                color={'white'}
                                colorScheme="teal"
                                _hover={{
                                    bg: 'teal.400',
                                }}>View in 360°</Button>
                            <Button onClick={openTwo}>Photos</Button>
                        </Box>
                    </Box>
                </Flex>
                <Flex w="full" alignItems="center" justifyContent="center">
                    <Box
                        bg={useColorModeValue('white', 'gray.800')}
                        maxW="lg"
                        h="lg"
                        borderWidth="1px"
                        rounded="lg"
                        shadow="lg"
                        position="relative">
                        <Image
                            h="300px"
                            fit="cover"
                            src="/Loft.jpg"
                            roundedTop="lg"
                        />
                        <Box p="6">
                            <Flex justifyContent="space-between" alignContent="center">
                                <Box
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    One Bedroom with Loft
                                </Box>
                            </Flex>
                            <Box d="flex" alignItems="baseline" mb="10">
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal" mr="2">
                                    <HStack spacing={"0"}>
                                        <Text mr="1">Sleeps</Text>
                                        <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill />
                                    </HStack>
                                </Badge>
                                <br></br>
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal">
                                    Seafront View
                                </Badge>
                            </Box>
                            <Button mr="3" onClick={() => window.open("https://my.matterport.com/show/?m=q6dcYN53gU8", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                color={'white'}
                                colorScheme="teal"
                                _hover={{
                                    bg: 'teal.400',
                                }}>View in 360°</Button>
                            <Button onClick={openOne}>Photos</Button>
                        </Box>
                    </Box>
                </Flex>
                <Flex w="full" alignItems="center" justifyContent="center">
                    <Box
                        bg={useColorModeValue('white', 'gray.800')}
                        maxW="lg"
                        h="lg"
                        borderWidth="1px"
                        rounded="lg"
                        shadow="lg"
                        position="relative">
                        <Image
                            h="300px"
                            fit="cover"
                            src="/Budget.jpg"
                            roundedTop="lg"
                        />
                        <Box p="6">
                            <Flex justifyContent="space-between" alignContent="center">
                                <Box
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Budget Traveler's Cabana
                                </Box>
                            </Flex>
                            <Box d="flex" alignItems="baseline" mb="10">
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal" mr="2">
                                    <HStack spacing={"0"}>
                                        <Text mr="1">Sleeps</Text>
                                        <BsFillPersonFill /> <BsFillPersonFill /> <BsFillPersonFill />
                                    </HStack>
                                </Badge>
                                <br></br>
                                <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="teal">
                                    Partial Seafront View
                                </Badge>
                            </Box>
                            <Button mr="3" onClick={() => window.open("https://my.matterport.com/show/?m=rdGMn2hHznK", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                color={'white'}
                                colorScheme="teal"
                                _hover={{
                                    bg: 'teal.400',
                                }}>View in 360°</Button>
                            <Button onClick={openBudget}>Photos</Button>
                        </Box>
                    </Box>
                </Flex>
            </SimpleGrid>
            <Heading fontSize={'3xl'} align={'center'} mb="6">Rates</Heading>
            <TableContainer mb={12}>
                <Table variant='simple'>
                    <TableCaption>Prices per night (updated November 2023). All rates are in USD.</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Accomodation Type</Th>
                            <Th>Low Season<br></br>(Jun 1 - Nov 20)</Th>
                            <Th>High Season<br></br>(Jan 4 - Jun 1)</Th>
                            <Th>Peak Season<br></br>(Nov 20 - Jan 4)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Budget Traveler's Cabana (3 guests)</Td>
                            <Td>$190</Td>
                            <Td>$205</Td>
                            <Td>$245</Td>
                        </Tr>
                        <Tr>
                            <Td>1 Bedroom and Loft (5 guests)</Td>
                            <Td>$290</Td>
                            <Td>$340</Td>
                            <Td>$385</Td>
                        </Tr>
                        <Tr>
                            <Td>2 Bedroom Cabana (6 guests)</Td>
                            <Td>$320</Td>
                            <Td>$375</Td>
                            <Td>$415</Td>
                        </Tr>
                        <Tr>
                            <Td>Prime Minister's Suite (6 guests)</Td>
                            <Td>$445</Td>
                            <Td>$500</Td>
                            <Td>$540</Td>
                        </Tr>
                        <Tr>
                            <Td>Additional Guests</Td>
                            <Td>$45/person</Td>
                            <Td>$45/person</Td>
                            <Td>$45/person</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="12">
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                    +9% hotel tax. +10% service charge.
                    A deposit of one night's stay, plus hotel tax and service charge is required at the time of booking.
                    Remaining balance is due 30 days prior to arrival.
                    If a reservation is canceled more than 30 days prior to arrival, we offer a full refund less $50 USD. If a reservation is canceled within 30 days of arrival, we are unable to offer a refund.
                    <br></br>
                    <br></br>
                    <b>Inclusions:</b>
                    <br></br>
                    A Cocktail Drink (per guest) - as a welcome gift from us
                    <br></br>
                    Unlimited Use of our Kayaks - paddle yourself into a frenzy
                    <br></br>
                    1 Roundtrip Boat Ride - to and from the resort
                </Text>
            </Stack>
            <Heading fontSize={'3xl'} align={'center'} mb="6">Island Life</Heading>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                pb={{ base: 10, md: 20 }}
                direction={{ base: 'column', md: 'row' }}>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>
                    <Box
                        position={'relative'}
                        height={'300px'}
                        rounded={'2xl'}
                        boxShadow={'lg'}
                        width={'full'}
                        overflow={'hidden'}>
                        <Image
                            alt={'Cabanas'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'130%'}
                            src={
                                '/cabanas-close.png'
                            }
                            transform={'translate(0%, -20%)'}
                        />
                    </Box>
                </Flex>
                <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        The largest island in Belize, Ambergris Caye receives refreshing trade breezes year round, meaning it’s always a good time to visit. Tranquility Bay lies within the UNESCO designated Bacalar Chico Marine Reserve making the renowned Belize Barrier Reef literally on your doorstep. We welcome those who want to relax and those who wish to explore—there’s something for everyone at Tranquility Bay. The resort houses the beloved restaurant ‘Aquarium’ as well as the lively Tackle Box Sea Bar—both of which are situated over the water for views of the fish below and provide the perfect sunset spot.
                    </Text>
                </Stack>
            </Stack>
        </Container >
    );
}

export const Blob = (props) => {
    return (
        <Icon
            width={'100%'}
            viewBox="0 0 578 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
                fill="currentColor"
            />
        </Icon>
    );
};

export default Cabanas;