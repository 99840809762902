import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './About';
import Activities from './Activities';
import './App.css';
import Cabanas from './Cabanas';
import Contact from './Contact';
import Dining from './Dining';
import Diving from './Diving';
import Excursions from './Excursions';
import Fishing from './Fishing';
import Footer from './Footer';
import Landing from './Landing';
import Navigation from './Navigation';
import NotFound from './NotFound';
import Romance from './Romance';
import Snorkeling from './Snorkeling';


function App() {
  return (
    <ChakraProvider>
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/activities/snorkeling" element={<Snorkeling />} />
          <Route path="/activities/diving" element={<Diving />} />
          <Route path="/activities/excursions" element={<Excursions />} />
          <Route path="/activities/fishing" element={<Fishing />} />
          <Route path="/dining" element={<Dining />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cabanas" element={<Cabanas />} />
          <Route path="/romance" element={<Romance />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
