import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react';



function Diving() {
    return (
        <div>
            <Container maxW={'7xl'} overflowX={'hidden'} pt={8}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Diving'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'130%'}
                                src={
                                    '/Diving-Feature.jpg'
                                }
                                transform={'translate(0%, 0%)'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                Diving instruction
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                for all ages and abilities
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            We can set up courses for all levels and would love to teach you about diving or help you improve your skills. Discover Scuba Diving is a PADI course for beginners. Inside the reef the water is calm with no waves so you can discover the underwater world in a controlled and safe manner. You may even find yourself accompanied by one of the area’s friendly residents – sea turtles, dolphins, rays, and manatees frequent the waters around PADI Open Water Certification courses take you out to explore the wonders of the aquatic world.
                        </Text>
                    </Stack>
                </Stack>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Divers, ages 10 and up, can get scuba diving certification. No prior experience with scuba diving is required. PADI Continuing Education will hone your skills, build your confidence, and keep you safe. We recommend completing PADI eLearning before coming to the resort. Doing the classroom portion of your training online means you can spend your vacation time in the water.Belize is one of the world’s top diving destinations and Tranquility Bay Resort’s beach is just 300 feet from the largest barrier reef in the Western Hemisphere. We are the only Ambergris Caye dive resort inside the Bacalar Chico Marine Reserve, a UNESCO World Heritage Site so you will dive in pristine waters among schools of fish, turtles, eels, rays and more.
                </Text>
            </Container>
            <Grid templateColumns='repeat(3, 1fr)'>
                <GridItem w='100%' >
                    <Image src="/diving1.jpg" roundedTopLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/diving2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/diving3.jpg" roundedTopRight={"2xl"} boxShadow={'lg'} />
                </GridItem>
            </Grid>
        </div>
    );
}

export default Diving;