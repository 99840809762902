import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue
} from '@chakra-ui/react';


function Activities() {
    return (
        <div>
            <Grid templateColumns='repeat(4, 1fr)' pb="8">
                <GridItem w='100%' >
                    <Image src="/Adventure1.jpg" roundedBottomLeft={"2xl"} boxShadow={'lg'}/>
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/Adventure2.jpg" boxShadow={'lg'}/>
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/Adventure3.jpg" boxShadow={'lg'}/>
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/Adventure4.jpg" roundedBottomRight={"2xl"} boxShadow={'lg'}/>
                </GridItem>
            </Grid>
            <Center>
                <Heading
                    pb="8"
                    fontWeight={600}
                    color="gray.800"
                    outlineColor={"black"}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    Let your {' '}
                    <Text as={'span'} color={'teal.400'}>
                        adventure begin
                    </Text>
                </Heading>
            </Center>
            <Container maxW={'7xl'} overflowX={'hidden'}>
                <Center pb="8">
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        Tranquility Bay Resort is on the northern end of Ambergris Caye, an island running parallel to the Belize Barrier Reef. The seclusion and proximity to the largest coral reef system in the Western Hemisphere makes our resort an exceptional place to explore above and beneath the water. Dive, snorkel, and fish to your heart’s content. And if you have energy for more, we can arrange excursions to the Mayan Ruins (Xunantunich, Altun Ha and Lamanai), the Belize Zoo, cave tubing, and zip‐lining.
                    </Text>
                </Center>
                <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(300px, 1fr))' mb="12">
                    <Flex w="full" alignItems="center" justifyContent="center">
                        <Box
                            bg={useColorModeValue('white', 'gray.800')}
                            maxW="lg"
                            h="lg"
                            borderWidth="1px"
                            rounded="lg"
                            shadow="lg"
                            position="relative">
                            <Image
                                h="300px"
                                w={{sm: "100%", md: "none"}}
                                fit="cover"
                                src="/diving.jpg"
                                roundedTop="lg"
                            />
                            <Stack pt={4} align={'center'}>
                                <Text
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Diving
                                </Text>
                                <Text mb="2" color="gray.600" textAlign={"center"} p="5" pb="2" pt="1">
                                    Tranquility Bay Resort’s beach is just 300 feet from the largest barrier reef in the Western Hemisphere.
                                </Text>
                                <Button onClick={() => window.location = "/activities/diving"} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                    color={'white'}
                                    colorScheme="teal"
                                    _hover={{
                                        bg: 'teal.400',
                                    }}>Discover More
                                </Button>
                            </Stack>
                        </Box>
                    </Flex>
                    <Flex w="full" alignItems="center" justifyContent="center">
                        <Box
                            bg={useColorModeValue('white', 'gray.800')}
                            maxW="lg"
                            h="lg"
                            borderWidth="1px"
                            rounded="lg"
                            shadow="lg"
                            position="relative">
                            <Image
                                h="300px"
                                w={{sm: "100%", md: "none"}}
                                fit="cover"
                                src="/fishing.jpg"
                                roundedTop="lg"
                            />
                            <Stack pt={4} align={'center'}>
                                <Text
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Fishing
                                </Text>
                                <Text mb="2" color="gray.600" textAlign={"center"} p="5" pb="2" pt="1">
                                    At Tranquility Bay Resort, world‐class fishing is just steps away from your cabana’s front door.
                                </Text>
                                <Button onClick={() => window.location = "/activities/fishing"} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                    color={'white'}
                                    colorScheme="teal"
                                    _hover={{
                                        bg: 'teal.400',
                                    }}>Discover More
                                </Button>
                            </Stack>
                        </Box>
                    </Flex>
                    <Flex w="full" alignItems="center" justifyContent="center">
                        <Box
                            bg={useColorModeValue('white', 'gray.800')}
                            maxW="lg"
                            h="lg"
                            borderWidth="1px"
                            rounded="lg"
                            shadow="lg"
                            position="relative">
                            <Image
                                h="300px"
                                w={{sm: "100%", md: "none"}}
                                fit="cover"
                                src="/snorkeling.jpg"
                                roundedTop="lg"
                            />
                            <Stack pt={4} align={'center'}>
                                <Text
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Snorkeling
                                </Text>
                                <Text mb="2" color="gray.600" textAlign={"center"} p="5" pb="2" pt="1">
                                    Snorkeling in the warm, calm, and clear water around the reef is a spectacular experience.
                                </Text>
                                <Button onClick={() => window.location = "/activities/snorkeling"} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                    color={'white'}
                                    colorScheme="teal"
                                    _hover={{
                                        bg: 'teal.400',
                                    }}>Discover More
                                </Button>
                            </Stack>
                        </Box>
                    </Flex>
                    <Flex w="full" alignItems="center" justifyContent="center">
                        <Box
                            bg={useColorModeValue('white', 'gray.800')}
                            maxW="lg"
                            h="lg"
                            borderWidth="1px"
                            rounded="lg"
                            shadow="lg"
                            position="relative">
                            <Image
                                h="300px"
                                w={{sm: "100%", md: "none"}}
                                fit="cover"
                                src="/excursions.jpg"
                                roundedTop="lg"
                            />
                            <Stack pt={4} align={'center'}>
                                <Text
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    as="h4"
                                    lineHeight="tight"
                                    isTruncated>
                                    Local Excursions
                                </Text>
                                <Text mb="2" color="gray.600" textAlign={"center"} p="5" pb="2" pt="1">
                                    There are also many wonderful things to do on the mainland of Belize.
                                </Text>
                                <Button onClick={() => window.location = "/activities/excursions"} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                                    color={'white'}
                                    colorScheme="teal"
                                    _hover={{
                                        bg: 'teal.400',
                                    }}>Discover More
                                </Button>
                            </Stack>
                        </Box>
                    </Flex>
                </SimpleGrid>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Blue Hole'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    '/bluehole.jpg'
                                }
                                transform={'translate(0%, 0%)'}
                                boxShadow={'lg'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading fontSize={'3xl'} align={'left'} >Exploration Made Easy</Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            Situated on 12 acres, Tranquility Bay Resort is the perfect choice for your home base on Ambergris Caye. With easy access to explore the many wonders of Belize both by water and on land, Tranquility Bay Resort is the only dive resort on Ambergris Caye within the Bacalar Chico Marine Reserve UNESCO World Heritage Site.
                            Our friendly and knowledgeable staff invite you to enjoy favorite Belize activities such as snorkeling, diving, and fishing. Just 300 feet from your private beach cabana lies the crystal blue water of the Belize Barrier Reef. The Belize Barrier Reef is the world’s second largest reef system and remains an undisturbed, dynamic underwater world inhabited by abundant, colorful marine life.
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    position={'relative'}
                    height={'300px'}
                    rounded={'2xl'}
                    width={'full'}
                    overflow={'hidden'}
                    mb="12"
                    boxShadow={'lg'}>
                    <Image
                        alt={'Coral Reef'}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                        src={
                            '/belizereef.jpg'
                        }
                    />
                </Box>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Often titled the ‘Jewel of Central America’, the Belize Barrier Reef is one of the planet’s most diverse ecosystems and home to three of the Caribbean’s four atolls. Additionally, the reef contains the famous ‘Great Blue Hole’ explored by Jacques Cousteau in 1970 and regarded as one of the world’s best diving locales. A wide variety of water sports are able to be enjoyed on Ambergris Caye, such as windsurfing, river and sea kayaking, sailing, jet skiing, parasailing, and kiteboarding. Whether you are a beginner or an expert, you’ll be able to easily enjoy many activities in Belize.
                </Text>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Here the Caribbean sea is a mere arm’s reach from the jungle making possible exciting opportunities to explore Belize’s varied land attractions. Lightly populated and still relatively a quiet destination, Belize contains 95 protected natural and cultural reserves. Sixty percent of the country is forested and it’s considered the best place in North America to spot the elusive jaguar in the wild. Tranquility Bay Resort is located away from the bustle of the city yet it’s only a quick journey to go into San Pedro town and beyond. Once a sleepy fishing village, San Pedro is now a welcoming hub of island life. Popular San Pedro Belize activities include visiting the ruins at Xunantunich, Altun Ha and Lamanai, archaeological sites of the Mayan empire’s heyday, 250-1000 AD. In these grand cities of the past empire, today’s visitor can ascend steep stairs of iconic stone temples and discover 360 degree jungle views. Other favorite activities of Tranquility Bay guests include meeting local wildlife at the Belize Zoo, cave tubing on underground rivers in the largest cave system in North America, soaring through the trees by zipline, trekking to waterfalls, and exploring the countryside by horseback. At Tranquility Bay Resort, the choice of activities is yours and we are pleased to assist you in discovering Belize.
                </Text>
            </Container>
        </div>
    );
}

export default Activities;