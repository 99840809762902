import {
    Box,
    Container,
    Image,
    Stack,
    Text,
    VisuallyHidden,
    chakra,
    useColorModeValue,
    useToken
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaPinterest } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';

const Logo = (props) => {
    return (
        <Image src="/logo.png" alt="Tranquility Bay Logo"
            maxHeight={{ base: '10' }}
            maxWidth={{ base: 'none' }}
        />
    );
};

const SocialButton = ({
    children,
    label,
    href,
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'teal.100',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function Footer() {
    const currentYear = new Date().getFullYear();
    const shouldShowFooter = true;
    const [socialIconColor] = useToken(
        'colors',
        ['teal.500'],
    );

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            {shouldShowFooter &&
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'space-between' }}
                    align={{ base: 'center', md: 'center' }}>
                    <Logo />
                    <Text color={'gray.600'}>© {currentYear} Tranquility Bay Resort & Spa</Text>
                    <Stack direction={'row'} spacing={6}>
                        <SocialButton label={'Instagram'} href={'https://www.instagram.com/tranquilitybaybelize/'}>
                            <FaInstagram color={socialIconColor} />
                        </SocialButton>
                        <SocialButton label={'Facebook'} href={'https://www.facebook.com/TranquilityBayResort/'}>
                            <FaFacebook color={socialIconColor} />
                        </SocialButton>
                        <SocialButton label={'Pinterest'} href={'https://www.pinterest.com/tranquilitybayresort/'}>
                            <FaPinterest color={socialIconColor} />
                        </SocialButton>
                        <SocialButton label={'X.com (formerly Twitter)'} href={'https://twitter.com/tranquilitybay'}>
                            <FaSquareXTwitter color={socialIconColor} />
                        </SocialButton>
                    </Stack>
                </Container>
            }
        </Box>

    );
}