import { Box, Button, Heading, Text } from '@chakra-ui/react';

export default function NotFound() {

    return (
        <Box textAlign="center" py={'20vh'} px={6} pb={'43vh'}>
            <Heading
                display="inline-block"
                as="h2"
                size="2xl"
                bgGradient="linear(to-r, teal.400, teal.600)"
                backgroundClip="text">
                404
            </Heading>
            <Text fontSize="18px" mt={3} mb={4}>
                Page Not Found
            </Text>
            <Text color={'gray.500'} mb={6} pl={{ base: '5vw', md: '30vw' }} pr={{ base: '5vw', md: '30vw' }}>
                This page cannot be found right now. Maybe it's sipping on a coconut cocktail at the bar or snorkeling around the coral reef. In the meantime, feel free to explore other sandy shores of our website.
            </Text>

            <Button
                onClick={() => window.location.pathname = "/"}
                colorScheme="teal"
                bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color="white"
                variant="solid">
                Take Me Back to Tranquility
            </Button>
        </Box >
    );
}