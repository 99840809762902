import {
    Box,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    IconButton,
    Image,
    Stack,
    Text,
    useBreakpointValue
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';


function Romance() {
    // Settings for the slider
    const settings = {
        arrows: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = useState(null);

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '40px' });

    // This list contains all the data for carousels
    // This can be static or loaded from a server
    const cards = [
        {
            author: 'Orla & David, Virginia',
            text:
                "The team at Tranquility Bay Resort worked so hard to make our dream Belize destination wedding absolutely perfect! From the tropical floral arrangements to the altar made out of palm trees, every detail was stunning and more than I could have ever imagined! Our guests all had the time of their lives and we always cherish the memories!",
        },
        {
            author: 'Alison & Michael, Maryland',
            text:
                "We decided to have our honeymoon in Belize and I think it was the perfect choice! Tranquility Bay was the perfect choice of accommodation & quiet, pretty, secluded. Every morning we enjoyed a wonderful breakfast, lounged on the private beach, stepped into the water and snorkeled around the reefs. It was paradise.",
        },
        {
            author: 'Jane, Illinois',
            text:
                "You can walk along the beach and the only sounds you hear are the waves of the ocean, birds, and the occasional thump of a coconut falling from the tree. I think the quiet intimacy of the island makes it one of the most romantic caribbean vacations that I’ve ever been on.",
        },
    ];

    return (
        <div>
            <Grid templateColumns='repeat(3, 1fr)' pb="8">
                <GridItem w='100%' >
                    <Image src="/wedding1.jpg" roundedBottomLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/wedding2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/wedding3.jpg" boxShadow={'lg'} />
                </GridItem>
            </Grid>
            <Center>
                <Heading
                    pb="8"
                    fontWeight={600}
                    color="gray.800"
                    outlineColor={"black"}
                    fontSize={{ base: '3xl', sm: '4xl', md: '4xl' }}
                    lineHeight={'110%'}
                    textAlign="center"
                    maxW={"60%"}>
                    Experience the Romantic Caribbean in Ambergris Caye's
                    <br></br>
                    <Text as={'span'} color={'teal.400'}>
                        Tranquility Bay Resort
                    </Text>
                </Heading>
            </Center>
            <Container maxW={'7xl'} overflowX={'hidden'}>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="12">
                    Imagine the perfect destination wedding! Nature has provided the beautiful sea, sand and palm tree lined backdrop. Let us provide an experience which will create lifelong memories. Our wedding accommodations are customizable to your heart's desire. Whether you want just you and your significant other or have your family and friends, we can customize your nuptial experience. At your request, we can provide catering, flowers, cakes and lodging. Just give us a call or email us to discuss.
                </Text>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 20 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'auto'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Wedding'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                src={
                                    '/wedding.jpg'
                                }
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading fontSize={'3xl'} align={'left'} >Romantic Belize is Relaxing and Unforgettable at Tranquility Bay Resort</Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            Our resort offers world-class hospitality with an intimate, homey and relaxed feel in romantic Belize. Grab a table for two in paradise, and indulge in Caribbean cuisine by candlelight on the beach or a care-free traditional creole style grill overlooking the water. Speak to a member of our friendly staff to make any special arrangements or surprises for your loved one. From day trip excursions visiting a private island to couples massages and spa treatments, we’re here to make your experience absolutely unforgettable.
                            <br></br><br></br>
                            If you’re looking for a stunning location, where you can feel absolutely relaxed and rested, then you need to consider Belize as your top destination of choice! Nowhere does romantic Caribbean vacations quite like Tranquility Bay Resort.
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    position={'relative'}
                    height={'300px'}
                    rounded={'2xl'}
                    width={'full'}
                    overflow={'hidden'}
                    mb="12"
                    boxShadow={'lg'}>
                    <Image
                        alt={'Beachfront View'}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                        src={
                            '/beachfront2.jpg'
                        }
                    />
                </Box>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="12">
                    Here at Tranquility Bay, we believe that romance isn’t dead. Belize is the perfect location for romantic Caribbean vacations, destination weddings, anniversaries, and couples getaways. Tranquility Bay is nestled within the famous Ambergris Caye area, sitting on a secluded beach with white sand, dotted with palm trees and surrounded by sparkling blue water.
                    <br></br><br></br>
                    The ultimate romantic getaway for couples looking for low‐key charm. Kick off your shoes when you arrive and grab yourself a fishing pole, snorkel, or a great book; then settle into your tropical home away from home in one of our beachfront cabanas. Whether you fancy taking it easy in a hammock or trying your hand at water sports, there is something on offer here to suit everyone’s tastes.
                    <br></br><br></br>
                    <b>* Please note: a Belize marriage license is required. Both parties must be in Belize for a minimum of three business days before your license can be issued. *</b>
                </Text>
                <Heading fontSize={'3xl'} align={'center'} mb="6">Testimonials</Heading>
                <Box
                    position={'relative'}
                    height={'sm'}
                    width={'full'}
                    overflow={'hidden'}
                    mb="12">
                    {/* CSS files for react-slick */}
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charSet="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    {/* Left Icon */}
                    <IconButton
                        aria-label="left-arrow"
                        variant="ghost"
                        position="absolute"
                        left={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        color={"teal.500"}
                        zIndex={2}
                        onClick={() => slider?.slickPrev()}>
                        <BiLeftArrowAlt size="40px" />
                    </IconButton>
                    {/* Right Icon */}
                    <IconButton
                        aria-label="right-arrow"
                        variant="ghost"
                        position="absolute"
                        right={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        color={"teal.500"}
                        zIndex={2}
                        onClick={() => slider?.slickNext()}>
                        <BiRightArrowAlt size="40px" />
                    </IconButton>
                    {/* Slider */}
                    <Slider {...settings} ref={(slider) => setSlider(slider)}>
                        {cards.map((card, index) => (
                            <Box
                                key={index}
                                height={'sm'}
                                position="relative"
                                borderStyle={"solid"}
                                borderColor={"teal.500"}
                                borderWidth={'5px'}
                                rounded={'2xl'}
                            >
                                {/* This is the block you need to change, to customize the caption */}
                                <Container size="container.lg" height='sm' position="relative">
                                    <Stack
                                        spacing={6}
                                        w={'full'}
                                        maxW={{ base: '90%', md: 'lg' }}
                                        position="absolute"
                                        top="50%"
                                        transform="translate(0, -50%)">
                                        <Text fontSize={{ base: 'md', lg: 'lg' }} textAlign={'left'} color={'gray.600'}>
                                            {card.text}
                                        </Text>
                                        <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight={'bold'} color="teal.500" textAlign={'right'}>
                                            {card.author}
                                        </Text>
                                    </Stack>
                                </Container>
                            </Box>
                        ))}
                    </Slider>
                </Box>
            </Container>
        </div>
    );
}

export default Romance;